/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useState, useEffect } from 'react';
import { Route, Routes, useNavigate, Navigate, useLocation } from 'react-router-dom';
import { ThemeDefault, ThemeRugby, ThemeAFL } from './theme';
import { NATIVE_SET_USER_KEY, PLAYER_STATUS, SPORT_TYPE } from './constants';

import Auth from './services/Auth';
import PlayerData from './services/PlayerData';
import DataLayer, { GTM4Events } from './services/DataLayer';
import Hotjar from './services/Hotjar';
import Content from './services/Content';
import Native from './services/Native';
import { IToastContext, ToastContextProvider, IToast } from './components/Toast/ToastContext/ToastContext';
import { GlobalStyle, StyledApp, StyledEnvironmentIndicator } from './App.styled';
import { LoadingSpinner } from './components/LoadingSpinner/LoadingSpinner';
import { Toast } from './components/Toast/Toast';
import { TransitionMask } from './components/Transition/TransitionMask/TransitionMask';
import { TransitionContextProvider } from './components/Transition/TransitionContext/TransitionContext';
import { getRoutes, IRoute } from './Routes';
import { ThemeProvider } from 'styled-components';
import Environment, { ENVIRONMENT_TYPE } from './services/Environment';
import CohortData from 'services/CohortData';
import MixPanel from 'services/MixPanel';

const ThemeSportMap = {
  [SPORT_TYPE.AustralianRules]: ThemeAFL,
  [SPORT_TYPE.AustralianRulesOneSession]: ThemeAFL,
  [SPORT_TYPE.Rugby]: ThemeRugby,
  [SPORT_TYPE.WoWTest]: ThemeDefault,
};
const FALLBACK_THEME = ThemeRugby;


export const App: React.FC = () => {
  const environment = Environment.getCurrentEnvironment();
  const navigate = useNavigate();
  const loc = useLocation();

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isInitialised, setIsInitialised] = useState(false);
  const [isArchivedUser, setArchivedUser] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState(ThemeDefault); //Pre-authenticated theme
  // Toast context setup
  const [toast, setToast] = useState<IToast | null>(null);
  const toastContext: IToastContext = {
    ...toast,
    setToast: (newToast?: IToast) => setToast(newToast || null),
  };

  useEffect(() => {
    (async () => {
      if (isInitialised) return; // fixes infinite render loop

      DataLayer.init();  //Try to initialise datalayer on mount
      Hotjar.init();  // try to initialize hotjar
      MixPanel.init();
      // Catch route changes for analytics (block happens BEFORE)
      // history && history.block((loc) => DataLayer.pushRouteChange(loc.pathname, window.location.pathname));

      const authenticated = await Auth.isAuthenticated();
      if (authenticated) {
        await PlayerData.loadPlayerData();
        await CohortData.loadCohortData();

        Content.setContentBase();
        const sport = PlayerData.getSport();

        //Now that we're authenticated we should know which cohort/sport to theme with
        if (sport && ThemeSportMap[sport]) {
          setSelectedTheme(ThemeSportMap[sport]);
        } else {
          //If the cohort has no sport, or there is no matching theme, use the fallback theme
          setSelectedTheme(FALLBACK_THEME);
        }

        // check if the user is an RCT user, or trying to navigate to another environment (TAKEMETOTEST)
        await Environment.tryShortcutAccessCodeRedirect(Auth.getTeamCode());

        //Check if the user is archived, if so- redirect to my-learnings (this also updates the state and locks down the routes)
        if (PlayerData.getArchiveStatus() === PLAYER_STATUS.Archived && !window.location.href.includes('/my-learnings')) {
          navigate('/my-learnings');
          setArchivedUser(true);
        }
      }

      setIsAuthenticated(authenticated);
      setIsInitialised(true);

      // eslint-disable-next-line @typescript-eslint/unbound-method, @typescript-eslint/no-unsafe-member-access
      (window as any)[NATIVE_SET_USER_KEY] = Native.setUserToken;
      if (authenticated && PlayerData.getNotificationSendingPermission()) {
        Native.requestNativeDeviceRegistration();
      }
      // eslint-disable-next-line no-console
    })().catch((e) => console.log(e));
  }, []);

  useEffect(()=>{
    //using DataLayer to store previousUrl, since history.block is no longer support in react router v6.
    const previousUrl = DataLayer.getPreviousUrl();
    const route: GTM4Events['pageView'] = {
      current_uri: location.pathname,
      last_uri: previousUrl,
      page_title: 'root',
    };
    DataLayer.pushRouteChange(loc.pathname, previousUrl );
    DataLayer.pushRouteChange_GA4(route);
    DataLayer.setPreviousUrl(window.location.pathname);
  }, [loc]);

  const routes: IRoute[] = getRoutes(isAuthenticated, isArchivedUser);

  return (
    <ThemeProvider theme={selectedTheme}>
      <StyledApp>
        {!Environment.isEnvironment(ENVIRONMENT_TYPE.PROD) && <StyledEnvironmentIndicator style={{ backgroundColor: environment.tagColor }}>{environment.name}</StyledEnvironmentIndicator>}
        <GlobalStyle />
        {!isInitialised ? (
          <LoadingSpinner backgroundColour={selectedTheme.colors.black}/>
        ) : (
          <TransitionContextProvider>
            <ToastContextProvider value={toastContext}>
              <Routes>
                {routes.map((route, idx) => (
                  <Route
                    key={idx}
                    path={route.path}
                    index={route.index ?? false}
                    element={route.elem}
                  />
                ))}
              </Routes>
              {toast && <Toast toast={toast} />}
            </ToastContextProvider>
            <TransitionMask />
          </TransitionContextProvider>
        )}
      </StyledApp>
    </ThemeProvider>
  );
};

export default App;
